define('ember-meta-meta/services/meta', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Service = _ember['default'].Service;

  exports['default'] = Service.extend({
    /**
     Store the default document title
      @method storeDefaultTitle
     */
    storeDefaultTitle: function storeDefaultTitle() {
      this.set('defaultTitle', window.document.title);
    },

    /**
     Set metas to be injected
      For exemple :
      ```js
     this.get('meta').update({
          title: 'This Is News Title',
          description: 'This Is News Description',
          'og:image': 'https://exemple.net/latest-news.png'
        });
     ```
      @method update
     @param {Object} attributes
     */
    update: function update(attributes) {
      this.set('attributes', attributes);
    },

    /**
     Inject given metas in document after completed didTransition
      @method insert
     */
    insert: function insert() {
      var _this = this;

      var attributes = this.get('attributes') || { title: this.get('defaultTitle') };
      var metas = document.querySelectorAll('meta[data-meta-meta="true"]');

      // Remove previously set metas
      for (var i = 0; i < metas.length; i++) {
        metas[i].removeAttribute('content');
      }

      // Set new metas
      Object.keys(attributes).forEach(function (key) {
        _this.setMeta(key, attributes[key]);
      });

      // Reset for next transition
      this.set('attributes', null);
    },

    /**
     Returns the title, exists solely to be reopen for your needs
      @method title
     */
    title: function title(value) {
      return value;
    },

    /**
     Inject given meta in document.
      Exemple :
      ```js
     self.router.setMeta('title', 'Homepage of the Crew');
     ```
      @method setMetas
     @param {Object} attributes
     */
    setMeta: function setMeta(name, value) {
      var meta = undefined,
          key = undefined;

      if (name === 'title') {
        window.document.title = this.title(value);
      } else {
        key = name.indexOf('og:') === -1 ? 'name' : 'property';
        meta = document.querySelector('meta[' + key + '="' + name + '"]');

        // Create meta tag if does not exist
        if (!meta) {
          meta = document.createElement('meta');
          meta.setAttribute(key, name);
          document.getElementsByTagName('head')[0].appendChild(meta);
        }

        meta.setAttribute('data-meta-meta', 'true');
        meta.setAttribute('content', value);
      }
    }
  });
});