define('ember-hypersearcher/components/hyper-search', ['exports', 'ember', 'ember-hypersearcher/templates/components/hyper-search'], function (exports, _ember, _emberHypersearcherTemplatesComponentsHyperSearch) {
  'use strict';

  var Component = _ember['default'].Component;
  var emberArray = _ember['default'].A;
  var _Ember$RSVP = _ember['default'].RSVP;
  var Promise = _Ember$RSVP.Promise;
  var resolve = _Ember$RSVP.resolve;
  var reject = _Ember$RSVP.reject;
  var ajax = _ember['default'].$.ajax;
  var _Ember$run = _ember['default'].run;
  var debounce = _Ember$run.debounce;
  var bind = _Ember$run.bind;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isBlank = _ember['default'].isBlank;
  var isPresent = _ember['default'].isPresent;
  var typeOf = _ember['default'].typeOf;

  /**
   * Returns the key for the query in the cache. Only works in conjunction with
   * Ember.get.
   *
   * @public
   * @param {String} query
   * @return {String} nested key name
   */
  function keyForQuery(query) {
    return '_cache.' + safeKeyString(query);
  }

  /**
   * Ensure string does not contain characters that will cause Ember.get to break
   *
   * IE: Replaces periods (.) with dashes (-)
   *
   * @public
   * @param {String} query
   * @return {String} safe key name
   */
  function safeKeyString(query) {
    return query.replace('.', '-');
  }

  exports['default'] = Component.extend({
    layout: _emberHypersearcherTemplatesComponentsHyperSearch['default'],
    minQueryLength: 3,
    debounceRate: 0,
    debounceAfter: true,
    endpoint: null,
    resultKey: null,
    placeholder: null,
    scrollOffset: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this._cache = {};
      this.results = emberArray();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeAllFromCache();
    },

    cache: function cache(query, results) {
      set(this, keyForQuery(query), results);
      this._handleAction('loadingHandler', false);
      return resolve(results);
    },

    getCacheForQuery: function getCacheForQuery(query) {
      return get(this, keyForQuery(query));
    },

    removeFromCache: function removeFromCache(query) {
      delete this._cache[safeKeyString(query)];
      this.notifyPropertyChange('_cache');
    },

    removeAllFromCache: function removeAllFromCache() {
      delete this._cache;
      set(this, '_cache', {});
    },

    clearResults: function clearResults() {
      get(this, 'results').clear();
      this.notifyPropertyChange('results');
    },

    fetch: function fetch(query) {
      if (isBlank(query) || query.length < get(this, 'minQueryLength')) {
        return reject();
      }

      this._handleAction('loadingHandler', true);

      var cachedValue = this.getCacheForQuery(query);

      if (isPresent(cachedValue)) {
        this._handleAction('loadingHandler', false);
        return resolve(cachedValue);
      } else {
        return this.requestAndCache.apply(this, arguments);
      }
    },

    /**
     * Override to handle the fetching of data. Must return a `Promise`.
     *
     * @public
     * @method request
     * @param {String} query
     * @return {Promise}
     */
    request: function request(query) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        ajax({
          dataType: 'json',
          method: 'GET',
          url: get(_this, 'endpoint'),
          data: { q: query }
        }).then(resolve, reject);
      });
    },

    requestAndCache: function requestAndCache(query) {
      var _this2 = this;

      return this.request(query).then(function (results) {
        return _this2.cache(query, results);
      })['catch'](function (error) {
        return reject(error);
      });
    },

    _search: function _search() {
      var value = arguments.length <= 0 || arguments[0] === undefined ? this.$('input').val() : arguments[0];

      return this.fetch(value).then(bind(this, this._setResults));
    },

    _setResults: function _setResults(results) {
      this._handleAction('handleResults', results);

      return set(this, 'results', results);
    },

    _handleAction: function _handleAction(actionName) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this.attrs && typeOf(this.attrs[actionName]) === 'function') {
        var _attrs;

        (_attrs = this.attrs)[actionName].apply(_attrs, args);
      } else {
        this.sendAction.apply(this, [actionName].concat(args));
      }
    },

    _getNext: function _getNext(increment) {
      var results = get(this, 'results');
      var maxIndex = get(results, 'length') - 1;
      if (maxIndex === -1) {
        return null;
      }
      var nextIndex = increment === 1 ? 0 : maxIndex;
      var limit = increment === 1 ? maxIndex : 0;
      if (Object.prototype.toString.call(results.any) === '[object Function]') {
        results.any(function (result, i) {
          if (get(result, 'isHighlighted')) {
            nextIndex = i === limit ? null : i + increment;
            return true;
          }
        });
        if (Object.prototype.toString.call(results.objectAt) === '[object Function]') {
          return results.objectAt(nextIndex);
        }
      }
      return {};
    },
    highlightResult: function highlightResult(resultToHighlight) {
      if (!resultToHighlight) {
        return;
      }
      var results = get(this, 'results');
      if (Object.prototype.toString.call(results.any) === '[object Function]') {
        results.any(function (result) {
          if (get(result, 'isHighlighted')) {
            set(result, 'isHighlighted', false);
            return true;
          }
        });
      }

      set(resultToHighlight, 'isHighlighted', true);

      // have jquery find and move the top position when arrowing down...
      /*
      * var jQuery -- if defined.;
      * */
      if (jQuery !== undefined) {
        var offset = get(this, 'scrollOffset');
        var highlightOffset = jQuery('.hypersearch-result.highlight').offset();
        if (highlightOffset) {
          var _top = highlightOffset.top + offset;
          if (_top > 0) {
            jQuery('body,html').animate({
              scrollTop: _top
            }, 50);
          }
        }
      }
    },
    actions: {
      selectHighlightedResult: function selectHighlightedResult() {
        var _this3 = this;

        var results = get(this, 'results');
        if (Object.prototype.toString.call(results.any) === '[object Function]') {
          results.any(function (result) {
            if (get(result, 'isHighlighted')) {
              _this3._handleAction('selectResult', result);
              return false; // stop the "commit"
            }
          });
        }
      },

      commit: function commit() {
        this._handleAction('commit');
      },

      search: function search(_event, query) {
        debounce(this, '_search', query, get(this, 'debounceRate'), get(this, 'debounceAfter'));
      },

      selectResult: function selectResult(result) {
        this._handleAction('selectResult', result);
      },

      highlightResult: function highlightResult(result) {
        this.highlightResult(result);
      },

      moveHighlightedResult: function moveHighlightedResult(increment) {
        this.highlightResult(this._getNext(increment));
      }
    }
  });
});