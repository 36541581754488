define('ember-hypersearcher/components/hypersearch-input', ['exports', 'ember-one-way-input/components/one-way-input'], function (exports, _emberOneWayInputComponentsOneWayInput) {
  'use strict';

  exports['default'] = _emberOneWayInputComponentsOneWayInput['default'].extend({
    classNames: 'hypersearch-input',
    name: 'query',
    type: 'text',

    KEY_EVENTS: {
      '38': 'onuparrow',
      '40': 'ondownarrow',
      '13': 'onenter'
    }
  });
});