define('ember-cli-simditor/components/simditor-editor', ['exports', 'ember', 'ember-cli-simditor/templates/components/simditor-editor'], function (exports, _ember, _emberCliSimditorTemplatesComponentsSimditorEditor) {
    /* global Simditor */
    'use strict';

    exports['default'] = _ember['default'].Component.extend({
        layout: _emberCliSimditorTemplatesComponentsSimditorEditor['default'],
        upload: false,
        tabIndent: true,
        toolbar: true,
        toolbarFloat: true,
        toolbarFloatOffset: 0,
        toolbarHidden: false,
        pasteImage: false,
        cleanPaste: false,
        defaultImage: 'assets/passed.png',
        placeholder: 'Type something here',
        locale: 'en-US',
        didInsertElement: function didInsertElement() {
            var _this = this;

            var self = this;
            Simditor.locale = this.get('locale');
            var options = {
                textarea: self.$('textarea'),
                upload: self.upload,
                tabIndent: self.tabIndent,
                toolbar: self.toolbar,
                toolbarFloat: self.toolbarFloat,
                toolbarFloatOffset: self.toolbarFloatOffset,
                toolbarHidden: self.toolbarHidden,
                pasteImage: self.pasteImage,
                cleanPaste: self.cleanPaste,
                defaultImage: self.defaultImage,
                placeholder: self.placeholder,
                params: self.params || {}
            };

            var editor = new Simditor(options);
            if (typeof this.value === 'string') {
                editor.setValue(this.value);
            }

            editor.on('valuechanged', function (e) {
                if (typeof _this.attrs.onValuechanged == 'function') {
                    _this.attrs.onValuechanged(e, editor);
                }

                if (typeof _this.attrs.update == 'function') {
                    _this.attrs.update(editor.getValue());
                }
            });

            editor.on('selectionchanged', function (e) {
                if (typeof _this.attrs.onSelectionchanged == 'function') {
                    _this.attrs.onSelectionchanged(e, editor);
                }
            });

            editor.on('decorate', function (e, el) {
                if (typeof _this.attrs.onDecorate == 'function') {
                    _this.attrs.onDecorate(e, el, editor);
                }
            });

            editor.on('undecorate', function (e, el) {
                if (typeof _this.attrs.onUndecorate == 'function') {
                    _this.attrs.onUndecorate(e, el, editor);
                }
            });

            editor.on('pasting', function (e, pasteContent) {
                if (typeof _this.attrs.onPasting == 'function') {
                    return _this.attrs.onPasting(e, pasteContent, editor);
                }
            });

            editor.on('focus', function (e) {
                if (typeof _this.attrs.onFocus == 'function') {
                    _this.attrs.onFocus(e, editor);
                }
            });

            editor.on('blur', function (e) {
                if (typeof _this.attrs.onBlur == 'function') {
                    _this.attrs.onBlur(e, editor);
                }
            });

            editor.on('destroy', function (e) {
                if (typeof _this.attrs.onDestroy == 'function') {
                    _this.attrs.onDestroy(e, editor);
                }
            });

            _ember['default'].run.scheduleOnce('afterRender', this, function (editor) {
                if (this.attrs.editor) {
                    this.attrs.editor.update(editor);
                }
            }, editor);
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);
            this && this.get('editor') && this.get('editor').destroy();
        }
    });
});