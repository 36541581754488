define('ember-one-way-input/components/one-way-input', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var get = _ember['default'].get;

  exports['default'] = Component.extend({
    tagName: 'input',
    type: 'text',
    attributeBindings: ['accept', 'autocomplete', 'autosave', 'checked', 'dir', 'disabled', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'max', 'min', 'multiple', 'name', 'pattern', 'placeholder', 'size', 'step', 'type', 'value', 'width'],
    KEY_EVENTS: {
      '13': 'onenter',
      '27': 'onescape'
    },
    _sanitizedValue: undefined,

    input: function input() {
      this._handleChangeEvent();
    },
    change: function change() {
      this._handleChangeEvent();
    },
    keyUp: function keyUp(event) {
      this._interpretKeyEvents(event);
    },

    _interpretKeyEvents: function _interpretKeyEvents(event) {
      var methodName = this.KEY_EVENTS[event.keyCode];

      if (methodName) {
        this._sanitizedValue = null;
        this._processNewValue.call(this, methodName, this._readAppropriateAttr());
      }
    },

    _handleChangeEvent: function _handleChangeEvent() {
      this._processNewValue.call(this, 'update', this._readAppropriateAttr());
    },

    _readAppropriateAttr: function _readAppropriateAttr() {
      var attr = undefined;
      if (get(this, 'type') === 'checkbox') {
        attr = 'checked';
      } else {
        attr = 'value';
      }

      return this.readDOMAttr(attr);
    },

    _processNewValue: function _processNewValue(methodName, rawValue) {
      var value = this.sanitizeInput(rawValue);
      var action = this.attrs[methodName];

      if (this._sanitizedValue !== value) {
        this._sanitizedValue = value;
        if (action) {
          action(value);
        }
      }
    },

    sanitizeInput: function sanitizeInput(input) {
      return input;
    },

    init: function init() {
      this._super.apply(this, arguments);
      this._sanitizedValue = get(this, 'value') || get(this, 'checked');
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._processNewValue.call(this, 'update', get(this, 'value') || get(this, 'checked'));
    }
  });
});